<template>
  <div class="brandMessage-container">
        <div class="header2">
            <van-nav-bar title="品牌详情" left-text="返回列表"  left-arrow @click-left="onClickLeft" ></van-nav-bar>
        </div>
        <div class="productDescribe">
          <van-cell-group>
            <van-cell title="品牌名称:" class='myCell' :value="brandLists.name || '-'" />
           </van-cell-group>
          <van-cell-group>
            <van-cell title="商标分类:"  class='myCell' :value="'第  '+brandLists.brandType +'  类'" />
           </van-cell-group>
          <van-cell-group>
            <van-cell title="商标注册证号:"  class='myCell' :value="brandLists.brandNo || '-'" />
           </van-cell-group>
            <van-cell-group>
            <van-cell title="商标注册证:" class='myCell swipe_cs' value="">
            <van-swipe :loop="false" :show-indicators=false> 
            <van-swipe-item v-for="(item,index) in brandLists.powerAttorneys" :key="index">
                <van-image
                    width="70"
                    height="70"
                    @click="showImg(item.url)"
                    :src="item.url"
                    />
            </van-swipe-item>
            </van-swipe>
            </van-cell>
           </van-cell-group>
          <van-cell-group>
            <van-cell title="商标所有者:" class='myCell' :value="brandLists.brandCompany || '-' " />
           </van-cell-group>
          <van-cell-group>
            <van-cell title="商标有效时间:" class='myCell' :value="brandLists.brandExpired || '-'" />
           </van-cell-group>
          <van-cell-group>
            <van-cell title="品牌经营类型:" class='myCell' :value="brandLists.licenseScope || '-'" />
           </van-cell-group>
            <van-cell-group v-if="brandLists.licenseScope != '自有品牌'">
                <van-cell title="授权链路说明:" class='myCell swipe_cs' value="">
                <van-swipe :loop="false" :show-indicators=false> 
                <van-swipe-item v-for="(item,index) in brandLists.authorizationDes" :key="index">
                    <van-image
                        width="70"
                        height="70"
                        @click="showImg(item.url)"
                        :src="item.url"/>
                </van-swipe-item>
                </van-swipe>
                </van-cell>
           </van-cell-group>
          <van-cell-group>
            <van-cell title="备注:" class='myCell' :value="brandLists.remarks || '-'" />
           </van-cell-group>
          <van-cell-group>
            <van-cell title="审核状态:" class='myCell'>
                <template #default>
                    <span v-if="flags" style="color: red;">{{brandLists.statusName}}</span>
                    <span v-else>{{brandLists.statusName}}</span>
                </template>
            </van-cell>
           </van-cell-group>
         
      </div>
        <div style="color: #133676; font-size: 14px; position: fixed; top: 14px; right: 20px; z-index: 1000" v-if="brandLists.showEditBtn">
        <van-popover v-model="showPopover" trigger="click" :actions="actions" @select="onSelect" placement="bottom-end" >
            <template #reference>
                <van-button class="showBtn"></van-button>
            </template>
        </van-popover>
      </div>
  </div>
</template>

<script>
import {ImagePreview} from 'vant'
import { Dialog } from 'vant';
import { Empty, Notify } from 'vant'
import {Toast} from 'vant'
export default {
    data(){
        return{
            showPopover: false,
            flags: true,
            actions: [
                { text: '编辑品牌', icon: 'edit' },
                { text: '删除品牌', icon: 'delete-o' },
            ],
            imgList:[],
            brandLists: [],
        }
    },
    created(){
        Toast.loading({
            message: '加载中...',
            overlay: true,
            duration: 300,
            forbidClick: true,
        });
        setTimeout(()=>{
            this.brandList();
        }, 100)
    },
    methods:{
        onSelect(action) {
            if(action.text == '编辑品牌'){
                this.updateBrand();
            }else{
                this.deleteBrand();
            }
        },
        // 编辑品牌
        updateBrand(){
            this.$router.push({
                path: '/updateBrand',
                query: {
                    id: this.$route.query.id
                }
            })
        },
        // 删除品牌
        deleteBrand(){
            Dialog.confirm({
                title: '删除品牌',
                message: '您确定要删除当前品牌吗???',
            })
            .then(() => {
                this.$http.ajax('/ffkj-main/merchantBrand/del',{
                    merchantId: localStorage.getItem('merchantId'),
                    brandId: this.$route.query.id
                },'get',{}).then(res=>{
                    if( res.code == 0 ){
                        Notify({ type: 'success', message: '删除成功'})
                        setTimeout(()=>{
                            this.onClickLeft();
                        }, 400)
                    }else{
                        Notify({ type: 'danger', message: res.message })
                    }
                })
            })
            .catch(() => {
                
            });
        },
        showImg(item){
            this.imgList.push(item)
            ImagePreview(this.imgList)
            this.imgList = [];
        },
        // 返回列表
        onClickLeft(){
            this.$router.push({
                path: '/brand',
                query: {
                    active: 1,
                }
             })
        },
        // 跳转授权信息
        setaccreditMessage(){
            let id = this.$route.query.id == undefined? this.$route.query.ids : this.$route.query.id;
            this.$router.push({
                path: '/accreditMessage',
                query: {
                    id: id,
                }
             })
        },
        // 产品详情数据回显
        brandList() {
            // this.loading = true
            this.$http.ajax('/ffkj-main/merchantBrand/page', {
                pageEnum: 'EDIT_PAGE',
                merchantId: window.localStorage.getItem('merchantId'),
                pageNum: 1,
                pageSize: 5000,
            }, 'get', {}).then(res => {
                if (res.code == 0) {
                    let id = this.$route.query.id == undefined? this.$route.query.ids : this.$route.query.id;
                    for(var i = 0; i<res.data.list.length;i++){
                        if(id == res.data.list[i].id ){
                            this.brandLists = res.data.list[i];
                            if(res.data.list[i].statusName != '' || res.data.list[i].statusName != undefined){
                                if(res.data.list[i].statusName.indexOf('不通过') != -1){
                                    this.flags = true;
                                }else{
                                    this.flags = false;
                                }
                            }
                        }
                    }
                }
                else {
                   Notify({ type: 'danger', message: res.message })
                }
                var timer = setTimeout(function () {
                    this.loading = false
                    window.clearTimeout(timer)
                }.bind(this), 300)
            })
        },
    }
}
</script>

<style scoped>

</style>